// import logo from './logo.svg';
import "./App.css";
import HomePage from "./pages/HomePage";
import { Routes, Route } from "react-router-dom";
import FormPage from "./pages/FormPage";
import ChooseColor from "./pages/ChooseColor";
import DetailsPage from "./pages/DetailsPage";
import ThankYouPage from "./pages/ThankYouPage";
import DoctorsImagesButterfly from "./pages/DoctorsImagesButterfly";

function App() {
  return (
    <div className="App">
      {/* <BrowserRouter> */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/merck-join-form" element={<FormPage />} />
        <Route path="/merck-choose-color" element={<ChooseColor />} />
        <Route path="/merck-info" element={<DetailsPage />} />
        <Route path="/merck-thank-you" element={<ThankYouPage />} />
        {/* <Route path="/butterfly" element={<DoctorsImagesButterfly />} /> */}

      </Routes>
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;


