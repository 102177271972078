import React, { useEffect } from "react";
import "../styles/HomePage.css";
import WomenImage from "../Images/womenimage.png";
import SingleLogo from "../Images/singlelogo.png";
import MultipleLogoFirst from "../Images/multiplelogofirst.png";
import MultipleLogoSecond from "../Images/multiplelogosecond.png";
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io";
import HeadingOne from "../Images/heading-one-page1.png";
import MerckLogo from "../Images/merck-logo.png";
import { useNavigate } from "react-router";
import SubHeadingOne from "../Images/home-page-sub-heading-one-mobile.png";
import SubHeadingTwo from "../Images/home-page-sub-heading-two-mobile.png";



const HomePage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  return (
    <div className="main-wrapper ">
      <div className="main-head homepage-main-head">
        <div className="logoWrapper">
          <img src={MerckLogo} alt="Merck" />
        </div>
      </div>
      <div className="main-content">
        <div className="main-heading-text custom-main-heading-text">
          <img src={HeadingOne} alt="merck-caption" />
        </div>

        <div className="home-page-sections-wrapper">
          <div className="home-left-section home-page-home-left-section">
   <div className="home-right-section mobile-home-right-section">
            <div className="women-icon-wrapper">
              <img src={WomenImage} alt="merckwomenicon" />
            </div>
          </div>

            <div className="sub-captions sub-caption-wrapper-one">
        
              <img src={SubHeadingOne} alt="merck sub title" />
            </div>
            <div className="logos-container">
              <div className="multipe-logos">
                <img src={MultipleLogoFirst} alt="Euthyrox" />
                <img src={MultipleLogoSecond} alt="Euthyrox" />

                {/* for adding second logo */}
                {/*<img src={} alt="Carbophage" /> */}
              </div>
              <div className="single-logos">
                <img src={SingleLogo} alt="Euthyrox" />
              </div>
            </div>

            <div className="sub-captions sub-caption-wrapper-two">
              {/* <h4>Help create,</h4>
              <h4>Improve and prolong lives</h4> */}
               <img src={SubHeadingTwo} alt="merck sub title" />
            </div>
          </div>
          <div className="home-right-section hide-mobile-home-right-section">
            <div className="women-icon-wrapper">
              <img src={WomenImage} alt="merckwomenicon" />
            </div>
          </div>
        </div>
      </div>


     

      <div className="other-details">
        <p>
          <span>1.</span>Adv Ther. 2019;36(Suppl 2): 47-58.
        </p>
        <p>
          <span>2.</span>
          https://www.merckgroup.com/en/expertise/our-business/healthcare.html
        </p>
      </div>
      <div className="main-bottom">
        <div className="next-and-backward-button-wrapper">
          <div className="back-button home-page-back-button">
            <div className="next-arrow-icon">
              <IoMdArrowRoundBack className="backward-forward-icon" />
            </div>
            <p>BACK</p>
          </div>
          <div
            className="next-button"
            onClick={() => navigate("/merck-join-form")}
          >
            {" "}
            <p>NEXT</p>{" "}
            <div className="back-arrow-icon">
              <IoMdArrowRoundForward className="backward-forward-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
