// import React, { useEffect } from "react";
// import "../styles/HomePage.css";
// import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io";
// import { useNavigate } from "react-router";
// import "../styles/FormPage.css";
// import "../styles/ChooseColor.css";
// import PinkColor from "../Images/pink-color.png";
// import GreenColor from "../Images/green-color.png";
// import LavendorColor from "../Images/lavendor-color.png";
// import BlueColor from "../Images/blue-color.png";
// import { FaUser } from "react-icons/fa";
// import { useState } from "react";
// import HeadingPage3 from "../Images/heading-page-3.png";
// import Heading2Page3 from "../Images/heading-2-page-3.png";
// import imageCompression from "browser-image-compression";
// import ButterFly from "./ButterFly";
// import ButterFlys from "../Images/butterfly-fly.gif";
// import ButterFlyGifGreen from "../Images/Butterfly_green_gif.gif";
// import ButterFlyGifBlue from "../Images/Butterfly_blue_gif.gif";
// import ButterFlyGifPurpple from "../Images/Butterfly_purpple_gif.gif";

// const ChooseColor = () => {
//   const navigate = useNavigate();
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [selectedColor, setSelectedColor] = useState(null);
//   const [showButterfly, setShowButterfly] = useState(false);
//   console.log("selectedColor=>", selectedColor);
//   const handleColorClick = (imageUrl, color) => {
//     setSelectedColor(imageUrl);
//     localStorage.setItem("setSelectedImage", imageUrl);
//     localStorage.setItem("selectedColor", color);
//     setShowButterfly(true);
//   };
//   useEffect(() => {
//     window.scrollTo(0, 0);

//     let timeoutId;

//     if (showButterfly) {
//       // Set a timeout to hide the butterfly after 5000 milliseconds
//       timeoutId = setTimeout(() => {
//         setShowButterfly(false);
//       }, 4000);
//     }

//     // Clear the timeout when the component unmounts or when showButterfly changes
//     return () => clearTimeout(timeoutId);
//   }, [showButterfly]);

//   localStorage.setItem("selectedImage", selectedImage);

//   // handled the hight quality image uploading with compression
//   const handleFileChange = async (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       try {
//         const options = {
//           maxSizeMB: 1, // Maximum file size allowed (1MB in this example)
//           maxWidthOrHeight: 1920, // Maximum width or height allowed
//           useWebWorker: true, // Use web workers for faster compression (optional)
//         };
//         const compressedFile = await imageCompression(file, options);
//         const reader = new FileReader();
//         reader.onload = () => {
//           setSelectedImage(reader.result);
//         };
//         reader.readAsDataURL(compressedFile);
//       } catch (error) {
//         console.error("Error compressing image:", error);
//       }
//     }
//   };

//   const handleNextClick = () => {
//     if (selectedColor && selectedImage) {
//       // Proceed to the next step
//       navigate("/merck-info");
//     }
//   };

//   return (
//     <div className="main-wrapper ">
//       <div
//         className="main-head main-head-join-form choose-color-main-head"
//         style={{
//           backgroundColor:
//             selectedColor === "pink"
//               ? "#e8378b"
//               : selectedColor === "green"
//               ? "#5ca595"
//               : selectedColor === "lavender"
//               ? "#602f84"
//               : selectedColor === "blue"
//               ? "#00a0e3"
//               : "transperant",
//         }}
//       >
//         <div className="choose-color-header left-choose-color-header">
//           <img src={HeadingPage3} alt="heading-merck" />
//         </div>

//         <div className="choose-color-header hide-choose-color-header">
//           <img
//             className="heading-2-page-4"
//             src={Heading2Page3}
//             alt="heading-merck"
//           />
//         </div>
//       </div>
//       <div className="main-content choose-color-main-content">
//         <div className="home-page-sections-wrapper">
//           <div className="home-left-section choose-color-home-left-section">
//             <div className="color-options-wrapper">
//               <div
//                 className="clickable-color"
//                 onClick={() => handleColorClick("pink", PinkColor)}
//                 // style={{
//                 //   border:
//                 //     selectedColor === "pink" ? "3px solid #e8378b" : "none",
//                 // }}
//               >
//                 {selectedColor == "pink" ? (
//                   <img src={ButterFlys} alt="pink-color" />
//                 ) : (
//                   <img src={PinkColor} alt="pink-color" />
//                 )}
//               </div>
//               <div
//                 className="clickable-color"
//                 onClick={() => handleColorClick("green", GreenColor)}
//                 // style={{
//                 //   border:
//                 //     selectedColor === "green" ? "3px solid #5ca595" : "none",
//                 // }}
//               >
//                 {selectedColor == "green" ? (
//                   <img src={ButterFlyGifGreen} alt="pink-color" />
//                 ) : (
//                   <img src={GreenColor} alt="pink-color" />
//                 )}
//               </div>
//             </div>
//             <div className="color-options-wrapper">
//               <div
//                 className="clickable-color"
//                 onClick={() => handleColorClick("lavender", LavendorColor)}
//                 // style={{
//                 //   border:
//                 //     selectedColor === "lavender" ? "3px solid #602f84" : "none",
//                 // }}
//               >
//                 {selectedColor == "lavender" ? (
//                   <img src={ButterFlyGifPurpple} alt="pink-color" />
//                 ) : (
//                   <img src={LavendorColor} alt="pink-color" />
//                 )}
//               </div>
//               <div
//                 className="clickable-color"
//                 onClick={() => handleColorClick("blue", BlueColor)}
//                 // style={{
//                 //   border:
//                 //     selectedColor === "blue" ? "3px solid #00a0e3" : "none",
//                 // }}
//               >
//                 {selectedColor == "blue" ? (
//                   <img src={ButterFlyGifBlue} alt="pink-color" />
//                 ) : (
//                   <img src={BlueColor} alt="pink-color" />
//                 )}
//               </div>
//             </div>

//             <div className="choose-color-text-below-options">
//               <h3>Choose a Color</h3>
//               {!selectedColor && (
//                 <div className="warning-message">Please select a color.*</div>
//               )}
//             </div>
//             {showButterfly && (
//         <div className="butterfly butterfly-wrapper-mobile">
//           <ButterFly />

//         </div>
//       )}
//           </div>

//           <div className="home-right-section choose-file-right-section">
//             <div className="choose-file-wrapper">
//               <label htmlFor="fileInput" className="file-selector-icon">
//                 {selectedImage ? (
//                   <img
//                     src={selectedImage}
//                     alt="Selected"
//                     className="user-icon-file-upload uploaded-image"
//                   />
//                 ) : (
//                   <FaUser className="user-icon-file-upload" />
//                 )}
//                 <input
//                   type="file"
//                   id="fileInput"
//                   className="file-input"
//                   accept="image/*"
//                   onChange={handleFileChange}
//                 />
//               </label>
//               <div className="file-sector-button">
//                 {!selectedImage && (
//                   <div className="warning-message">Please choose an image*</div>
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {showButterfly && (
//         <div className="butterfly butterfly-wrapper-desktop">
//           <ButterFly />

//         </div>
//       )}

//       {/* <div className="other-details other-details-join-form">
//         <p>*Data as on Merck File</p>
//       </div> */}

//       <div className="other-details custom-other-details-choosecolor-page">
//         <p>*Data as on Merck File</p>
//       </div>

//       <div
//         className="main-bottom"
//         style={{
//           backgroundColor:
//             selectedColor === "pink"
//               ? "#e8378b"
//               : selectedColor === "green"
//               ? "#5ca595"
//               : selectedColor === "lavender"
//               ? "#602f84"
//               : selectedColor === "blue"
//               ? "#00a0e3"
//               : "transperant",
//         }}
//       >
//         <div className="next-and-backward-button-wrapper">
//           <div
//             className="back-button"
//             onClick={() => navigate("/merck-join-form")}
//           >
//             <div className="next-arrow-icon">
//               <IoMdArrowRoundBack className="backward-forward-icon" />
//             </div>
//             <p>BACK</p>
//           </div>
//           <div
//             className="next-button"
//             className={`next-button ${selectedColor ? "" : "disabled"}`}
//             onClick={handleNextClick}
//             disabled={!selectedColor}
//           >
//             {" "}
//             <p>NEXT</p>{" "}
//             <div className="back-arrow-icon">
//               <IoMdArrowRoundForward className="backward-forward-icon" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChooseColor;

import React, { useEffect } from "react";
import "../styles/HomePage.css";
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io";
import { useNavigate } from "react-router";
import "../styles/FormPage.css";
import "../styles/ChooseColor.css";
import PinkColor from "../Images/pink-color.png";
import GreenColor from "../Images/green-color.png";
import LavendorColor from "../Images/lavendor-color.png";
import BlueColor from "../Images/blue-color.png";
import { FaUser } from "react-icons/fa";
import { useState } from "react";
import HeadingPage3 from "../Images/heading-page-3.png";
import Heading2Page3 from "../Images/heading-2-page-3.png";
import imageCompression from "browser-image-compression";
import ButterFly from "./ButterFly";
import ButterFlys from "../Images/butterfly-fly.gif";
import ButterFlyGifGreen from "../Images/Butterfly_green_gif.gif";
import ButterFlyGifBlue from "../Images/Butterfly_blue_gif.gif";
import ButterFlyGifPurpple from "../Images/Butterfly_purpple_gif.gif";
import axios from "axios";

const ChooseColor = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [showButterfly, setShowButterfly] = useState(false);
  console.log("selectedColor=>", selectedColor);
  console.log("selectedImage>>>>>", selectedImage);

  const handleColorClick = (imageUrl, color) => {
    setSelectedColor(imageUrl);
    localStorage.setItem("setSelectedImage", imageUrl);
    localStorage.setItem("selectedColor", color);
    setShowButterfly(true);
  };
  useEffect(() => {
    let timeoutId;

    if (showButterfly) {
      // Set a timeout to hide the butterfly after 5000 milliseconds
      timeoutId = setTimeout(() => {
        setShowButterfly(false);
      }, 4000);
    }

    // Clear the timeout when the component unmounts or when showButterfly changes
    return () => clearTimeout(timeoutId);
  }, [showButterfly]);

  localStorage.setItem("selectedImage", selectedImage);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNo: "",
    city: "",
    state: "",
    title: "",
  });
  // console.log("formdata in choose color",formData);

  useEffect(() => {
    window.scrollTo(0, 0);
    const storedFormData = localStorage.getItem("formData");
    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }
  }, []);

  // handled the high-quality image uploading with compression
  const handleFileChange = async (event) => {
    //   // Prevent default form submission behavior
    // event.preventDefault();
    const file = event.target.files[0];
    if (file) {
      try {
        const options = {
          maxSizeMB: 10, // Maximum file size allowed (1MB in this example)
          // maxWidthOrHeight: 1920, // Maximum width or height allowed
          useWebWorker: true, // Use web workers for faster compression (optional)
        };
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }

    // // Automatically submit the form
    // event.target.form.submit();
  };

 




  const handleNextClick = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
  
    if (selectedColor && selectedImage) {
      try {
        // Submit the form
        const formElement = document.getElementById("myForm"); // Replace "myForm" with the actual ID of your form
        const formData = new FormData(formElement);
        // You can append additional data to formData if needed
        formData.append("color", selectedColor);
  
        // Perform your form submission here
        await fetch("https://www.euthyroxearlyscreeninginitiative.com/register.php", {
          method: "POST",
          body: formData,
        });
  
        // Wait for the form submission to complete (optional)
        // await new Promise((resolve) => setTimeout(resolve, 1000)); // Adjust the timeout as needed
  
        // Navigate to the next page
        navigate("/merck-info");
      } catch (error) {
        console.error("Error submitting form:", error);
        // Handle any errors that occur during form submission
      }
    }
  };


  

  return (
    <div className="main-wrapper ">
      <div
        className="main-head main-head-join-form choose-color-main-head"
        style={{
          backgroundColor:
            selectedColor === "pink"
              ? "#e8378b"
              : selectedColor === "green"
              ? "#5ca595"
              : selectedColor === "lavender"
              ? "#602f84"
              : selectedColor === "blue"
              ? "#00a0e3"
              : "#e8378b",
        }}
      >
        <div className="choose-color-header left-choose-color-header">
          <img src={HeadingPage3} alt="heading-merck" />
        </div>

        <div className="choose-color-header hide-choose-color-header">
          <img
            className="heading-2-page-4"
            src={Heading2Page3}
            alt="heading-merck"
          />
        </div>
      </div>
      <div className="main-content choose-color-main-content">
        <div className="home-page-sections-wrapper">
          <div className="home-left-section choose-color-home-left-section">
            <div className="color-options-wrapper">
              <div
                className="clickable-color"
                onClick={() => handleColorClick("pink", PinkColor)}
              >
                {selectedColor === "pink" ? (
                  <img src={ButterFlys} alt="pink-color" />
                ) : (
                  <img src={PinkColor} alt="pink-color" />
                )}
              </div>
              <div
                className="clickable-color"
                onClick={() => handleColorClick("green", GreenColor)}
              >
                {selectedColor === "green" ? (
                  <img src={ButterFlyGifGreen} alt="green-color" />
                ) : (
                  <img src={GreenColor} alt="green-color" />
                )}
              </div>
            </div>
            <div className="color-options-wrapper">
              <div
                className="clickable-color"
                onClick={() => handleColorClick("lavender", LavendorColor)}
              >
                {selectedColor === "lavender" ? (
                  <img src={ButterFlyGifPurpple} alt="lavender-color" />
                ) : (
                  <img src={LavendorColor} alt="lavender-color" />
                )}
              </div>
              <div
                className="clickable-color"
                onClick={() => handleColorClick("blue", BlueColor)}
              >
                {selectedColor === "blue" ? (
                  <img src={ButterFlyGifBlue} alt="blue-color" />
                ) : (
                  <img src={BlueColor} alt="blue-color" />
                )}
              </div>
            </div>

            <div className="choose-color-text-below-options">
              <h3>Choose a Color</h3>
              {!selectedColor && (
                <div className="warning-message">Please select a color.*</div>
              )}
            </div>
            {showButterfly && (
              <div className="butterfly butterfly-wrapper-mobile">
                <ButterFly />
              </div>
            )}
          </div>

          <div className="home-right-section choose-file-right-section">
            <div className="choose-file-wrapper">
              <label htmlFor="fileInput" className="file-selector-icon">
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="user-icon-file-upload uploaded-image"
                  />
                ) : (
                  <FaUser className="user-icon-file-upload" />
                )}
                <form
                  action="https://www.euthyroxearlyscreeninginitiative.com/register.php"
                  method="post"
                  encType="multipart/form-data"
                  id="myForm"
                >
                  <input name="name" value={`${formData.title} ${formData.name}`} type="hidden" />
                  <input name="email" value={formData.email} type="hidden" />
                  {/* <input name="title" value={formData.title} type="hidden" /> */}

                  <input
                    name="phone"
                    value={formData.contactNo}
                    type="hidden"
                  />
                  <input name="city" value={formData.city} type="hidden" />
                  <input name="state" value={formData.state} type="hidden" />
                  <input name="color" value={selectedColor} type="hidden" />

                  <input
                    type="file"
                    id="fileInput"
                    className="file-input"
                    accept="image/*"
                    onChange={handleFileChange}
                    name="profile_image"
                  />
                  <button
                    id="form_submit"
                    type="submit"
                    style={{ display: "none" }}
                  >
                    Upload Image
                  </button>
                </form>
                {/* <p>{formData.name}</p>
                <p>{formData.email}</p>
                <p>{formData.contactNo}</p>
                <p>{formData.city}</p>
                <p>{formData.state}</p>
                <p>{selectedColor}</p> */}
              </label>
              <div className="file-sector-button">
                {!selectedImage && (
                  <div className="warning-message">Please choose an image*</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showButterfly && (
        <div className="butterfly butterfly-wrapper-desktop">
          <ButterFly />
        </div>
      )}

      <div className="other-details custom-other-details-choosecolor-page">
        <p>*Data as on Merck File</p>
      </div>

      <div
        className="main-bottom"
        style={{
          backgroundColor:
            selectedColor === "pink"
              ? "#e8378b"
              : selectedColor === "green"
              ? "#5ca595"
              : selectedColor === "lavender"
              ? "#602f84"
              : selectedColor === "blue"
              ? "#00a0e3"
              : "transparent",
        }}
      >
        <div className="next-and-backward-button-wrapper">
          <div
            className="back-button"
            onClick={() => navigate("/merck-join-form")}
          >
            <div className="next-arrow-icon">
              <IoMdArrowRoundBack className="backward-forward-icon" />
            </div>
            <p>BACK</p>
          </div>
          <div
            className={`next-button ${selectedColor ? "" : "disabled"}`}
            onClick={handleNextClick}
            // onClick={() => navigate("/merck-info")}
            disabled={!selectedColor}
          >
            <p>NEXT</p>
            <div className="back-arrow-icon">
              <IoMdArrowRoundForward className="backward-forward-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseColor;
