import React, { useEffect, useState } from "react";
import "../styles/HomePage.css";
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io";
import { useNavigate } from "react-router";
import "../styles/FormPage.css";
import "../styles/ChooseColor.css";
import "../styles/DetailsPage.css";
import "../styles/ThankYouPage.css";
import HeadingPage3 from "../Images/heading-page-3.png";
import Heading2Page3 from "../Images/heading-2-page-3.png";
import ThankYouTitle from "../Images/thank-you-title.png";
import DisclaimerImage from "../Images/disclaimer.png";
import BorderPinkButterFly from "../Images/Pink_outline.png";
import BorderGreenButterFly from "../Images/Green_outline.png";
import BorderPurpleButterFly from "../Images/Purpple_outline.png";
import BorderBlueButterFly from "../Images/Blue_outline.png";
import ThankYouTitleMobile from "../Images/thank-you-title-mobile.png";
import BlankImage from "../Images/Blank-white-image.png";

import axios from "axios";
const ThankYouPage = () => {
  const navigate = useNavigate();

  const selectedImage = localStorage.getItem("selectedImage");
  const selectedColor = localStorage.getItem("selectedColor");

  const [images, setImages] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    name: "",
    email: "",
    // contactNo: "",
    // city: "",
    // state: "",
  });
  const image1 = images.length >= 1 ? images[0] : BlankImage;
  const image2 = images.length >= 2 ? images[1] : BlankImage;
  const image3 = images.length >= 3 ? images[2] : BlankImage;
  const image4 = images.length >= 4 ? images[3] : BlankImage;
  const image5 = images.length >= 5 ? images[4] : BlankImage;
  const image6 = images.length >= 6 ? images[5] : BlankImage;
  const image7 = images.length >= 7 ? images[6] : BlankImage;
  const image8 = images.length >= 8 ? images[7] : BlankImage;
  const image9 = images.length >= 9 ? images[8] : BlankImage;
  const image10 = images.length >= 10 ? images[9] : BlankImage;
  const image11 = images.length >= 11 ? images[10] : BlankImage;
  const image12 = images.length >= 12 ? images[11] : BlankImage;
  const image13 = images.length >= 13 ? images[12] : BlankImage;
  const image14 = images.length >= 14 ? images[13] : BlankImage;

  const nameFromFormData = formData.name;
  const titleFromFormData = formData.title;

  const emailFromFormData = formData.email;

  console.log("email and name", formData.name, formData.email);
  const [apiCountData, setApiCountData] = useState(null);
  console.log("apiCountData", apiCountData);

  console.log("images------>", images);

  console.log("selelctedImage", selectedImage);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Retrieve the form data from localStorage when the component mounts
    const storedData = localStorage.getItem("formData");

    const newColor = localStorage.getItem("selectedColor");
    window.scrollTo(0, 0);

    // Fetch images from the API when the component mounts
    axios
      .get("https://www.euthyroxearlyscreeninginitiative.com/getprofile.php")
      .then((response) => {
        setImages(response.data);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });

    const storedFormData = localStorage.getItem("formData");
    console.log("stored form data", storedFormData);
    setFormData(JSON.parse(storedFormData));
    // Define the data to send to the API
    const requestEmailData = formData.email;
    // const requestNameData = formData.name;

    console.log("requestEmailData-----------", requestEmailData);

    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        // Make GET request to the API using Axios
        const response = await axios.get(
          "https://www.euthyroxearlyscreeninginitiative.com/getcount.php"
        );
        // Set the response data to the state
        setApiCountData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error if needed
      }
    };
    fetchData();

    // Make a POST request to the API using Axios
    // axios
    //   .post(
    //     "https://www.euthyroxearlyscreeninginitiative.com/sendmail.php",
    //     requestEmailData
    //   )
    //   .then((response) => {
    //     console.log("Email sent successfully:", response.data);
    //     // Handle success if needed
    //   })
    //   .catch((error) => {
    //     console.error("Error sending email:", error);
    //     // Handle error if needed
    //   });

    // Function to send email
    const sendEmail = async () => {
      try {
        // Make POST request to the API using Axios
        const response = await axios.post(
          "https://www.euthyroxearlyscreeninginitiative.com/sendmail.php",
          storedData // Include name and email in the request body
        );
        console.log("Email sent successfully:", response.data);
        // Handle success if needed
      } catch (error) {
        console.error("Error sending email:", error);
        // Handle error if needed
      }
    };
    sendEmail();
  }, []);

  // useEffect(() => {

  // }, []);

  return (
    <div className="main-wrapper ">
      <div
        className="main-head main-head-join-form choose-color-main-head"
        style={{
          backgroundColor:
            selectedColor ===
            "/static/media/pink-color.aaee0039f5215db2bf72.png"
              ? "#e8378b"
              : selectedColor ===
                "/static/media/green-color.4d0a2dcf4e746215a4f4.png"
              ? "#5ca595"
              : selectedColor ===
                "/static/media/lavendor-color.2b158919bece3419437f.png"
              ? "#602f84"
              : selectedColor ===
                "/static/media/blue-color.eed63dd9c51401c338a6.png"
              ? "#00a0e3"
              : "transperant",
        }}
      >
        <div className="choose-color-header left-choose-color-header">
          <img src={HeadingPage3} alt="heading-merck" />
        </div>

        <div className="choose-color-header hide-choose-color-header">
          <img
            className="heading-2-page-4"
            src={Heading2Page3}
            alt="heading-merck"
          />
        </div>
      </div>

      <div className="main-content choose-color-main-content">
        <div className="home-page-sections-wrapper thank-you-home-page-sections-wrapper">
          {/* <h2>
            Thank u Doctor For Adding back color to the life of Thyroid patients
          </h2> */}
          <img
            src={ThankYouTitle}
            alt="thank-you"
            className="thank-you-title"
          />

          <img
            src={ThankYouTitleMobile}
            alt="thank-you"
            className="thank-you-title thank-you-title-mobile"
          />

          <div className="butterfly-main-wrap">
            {/* <div className="docotors-images-wrapper">
              <img src={BlankImage} alt="" className="blank" />

              {images.map((imageUrl, index) => (
                <img key={index} src={imageUrl} alt={`Image ${index}`} />
              ))}
            </div> */}
            <div className="docotors-images-wrapper">
              <img src={BlankImage} alt="" className="blank-first" />

              <img src={image1} alt="Image 1" />
              <img src={image2} alt="Image 2" className="image-two" />
              <img src={BlankImage} alt="" className="blank-second" />

              <img src={image3} alt="Image 3" className="image-third" />

              <img src={image4} alt="Image 4" className="image-third" />
              <img src={image5} alt="Image 5" />
              <img src={image6} alt="Image 6" className="image-third" />

              <img src={image7} alt="Image 8" className="image-third" />
              <img src={image8} alt="Image 9" />

              <img src={image9} alt="Image 10" />

              <img
                src={BlankImage}
                alt=""
                className="blank hidden-blank-mobile"
              />

              <img src={image10} alt="Image 1" className="hidden-image-xs" />
              <img src={image11} alt="Image 2" className="hidden-image-xs" />

              <img src={image12} alt="Image 3" className="hidden-image-xs" />

              <img src={image13} alt="Image 4" className="hidden-image-xs" />
              <img src={image14} alt="Image 4" className="hidden-image-xs" />
            </div>

            <div className="boundary-butterfly-wrapper">
              {selectedColor ===
                "/static/media/pink-color.aaee0039f5215db2bf72.png" && (
                <img
                  src={BorderPinkButterFly}
                  alt="Selected"
                  className="thank-you-women-image"
                />
              )}

              {selectedColor ===
                "/static/media/green-color.4d0a2dcf4e746215a4f4.png" && (
                <img
                  src={BorderGreenButterFly}
                  alt="Selected"
                  className="thank-you-women-image"
                />
              )}
              {selectedColor ===
                "/static/media/lavendor-color.2b158919bece3419437f.png" && (
                <img
                  src={BorderPurpleButterFly}
                  alt="Selected"
                  className="thank-you-women-image"
                />
              )}
              {selectedColor ===
                "/static/media/blue-color.eed63dd9c51401c338a6.png" && (
                <img
                  src={BorderBlueButterFly}
                  alt="Selected"
                  className="thank-you-women-image"
                />
              )}
            </div>
          </div>
        </div>

        {/* <DoctorsInsideButterFly/> */}
        <div className="disclaimer">
          <div className="total-count">
            {" "}
            <p> Total count: {apiCountData}</p>
          </div>
          <img src={DisclaimerImage} alt="disclaimer image" />
          <div className="other-details other-details-join-form">
            <p>*Data as on Merck File</p>
          </div>
        </div>
        <div></div>
      </div>

      <div
        className="main-bottom"
        style={{
          backgroundColor:
            selectedColor ===
            "/static/media/pink-color.aaee0039f5215db2bf72.png"
              ? "#e8378b"
              : selectedColor ===
                "/static/media/green-color.4d0a2dcf4e746215a4f4.png"
              ? "#5ca595"
              : selectedColor ===
                "/static/media/lavendor-color.2b158919bece3419437f.png"
              ? "#602f84"
              : selectedColor ===
                "/static/media/blue-color.eed63dd9c51401c338a6.png"
              ? "#00a0e3"
              : "transperant",
        }}
      >
        <div className="next-and-backward-button-wrapper">
          <div className="back-button" onClick={() => navigate("/merck-info")}>
            <div className="next-arrow-icon">
              <IoMdArrowRoundBack className="backward-forward-icon" />
            </div>
            <p>BACK</p>
          </div>
          <div
            className="next-button thank-you-next-button"
            onClick={() => navigate("/")}
          >
            {" "}
            <p>HOME</p>{" "}
            <div className="back-arrow-icon">
              <IoMdArrowRoundForward className="backward-forward-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
