import React, { useState, useEffect } from "react";
import "../styles/ButterFly.css"; // Assuming your CSS file is named ButterflyAnimation.css
import ButterFlys from "../Images/butterfly-fly.gif";
import ButterFlyGifGreen from "../Images/Butterfly_green_gif.gif";
import ButterFlyGifBlue from "../Images/Butterfly_blue_gif.gif";
import ButterFlyGifPurpple from "../Images/Butterfly_purpple_gif.gif";
const ButterFly = () => {
  const [animationState, setAnimationState] = useState("start");
  const selectedButterFlyGif = localStorage.getItem("selectedColor");
  useEffect(() => {
    // Triggering animation
    setTimeout(() => {
      setAnimationState("end");
    }, 4000); // Delay for starting the animation
  }, []);

  return (
    <div className={`butterfly-container ${animationState}`}>
      {/* <img src={ButterFlys} alt="Butterfly" className="butterfly" /> */}
      {selectedButterFlyGif ==
      "/static/media/pink-color.aaee0039f5215db2bf72.png" ? (
        <img src={ButterFlys} alt="pink-color" className="butterfly" />
      ) : null}
      {selectedButterFlyGif ==
      "/static/media/green-color.4d0a2dcf4e746215a4f4.png" ? (
        <img src={ButterFlyGifGreen} alt="pink-color" className="butterfly" />
      ) : null}
      {selectedButterFlyGif ==
      "/static/media/lavendor-color.2b158919bece3419437f.png" ? (
        <img src={ButterFlyGifPurpple} alt="pink-color" className="butterfly" />
      ) : null}
      {selectedButterFlyGif ==
      "/static/media/blue-color.eed63dd9c51401c338a6.png" ? (
        <img src={ButterFlyGifBlue} alt="pink-color" className="butterfly" />
      ) : null}
    </div>
  );
};

export default ButterFly;
