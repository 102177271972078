import React, { useEffect, useState } from "react";
import "../styles/HomePage.css";
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io";
import { useNavigate } from "react-router";
import "../styles/FormPage.css";
import "../styles/ChooseColor.css";
import ChoosedColorImage from "../Images/choosed-multi-color.png";

import "../styles/DetailsPage.css";
import PersonImage from "../Images/person-image.png";
import QrCode from "../Images/qr-code.png";
import HeadingPage3 from "../Images/heading-page-3.png";
import Heading2Page3 from "../Images/heading-2-page-3.png";
import ButterFlys from "../Images/butterfly-fly.gif";
import ButterFlyGifGreen from "../Images/Butterfly_green_gif.gif";
import ButterFlyGifBlue from "../Images/Butterfly_blue_gif.gif";
import ButterFlyGifPurpple from "../Images/Butterfly_purpple_gif.gif";
import axios from "axios";
const DetailsPage = () => {
  const navigate = useNavigate();
  const selectedImage = localStorage.getItem("selectedImage");
  const selectedColor = localStorage.getItem("selectedColor");

  console.log("selectedImage------>>>>>>>", selectedImage);
  const [storedFormData, setStoredFormData] = useState({
    name: "",
    city: "",
    state: "",
    title: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);

    // Retrieve the form data from localStorage when the component mounts
    const storedData = localStorage.getItem("formData");
    const newColor = localStorage.getItem("selectedColor");
    console.log("new color", newColor);
    if (storedData) {
      setStoredFormData(JSON.parse(storedData));
    }
  }, []);

  const handleNextClick = async () => {
    if (selectedImage && selectedColor) {
      try {
        const formData = new FormData();
        formData.append("profile_image", selectedImage);
        const response = await axios.post(
          "https://www.euthyroxearlyscreeninginitiative.com/register.php",
          formData
        );
        console.log("Response Details Page:", response.data);
        // Proceed to the next step
        navigate("/merck-thank-you");
      } catch (error) {
        console.error("Error uploading image:", error);
        console.error("Server response:", error.response.data);
      }
    }
  };

  return (
    <div
      className="main-wrapper details-page-custom-wrapper"
      style={{
        backgroundColor:
          selectedColor === "/static/media/pink-color.aaee0039f5215db2bf72.png"
            ? "#e8378b"
            : selectedColor ===
              "/static/media/green-color.4d0a2dcf4e746215a4f4.png"
            ? "#5ca595"
            : selectedColor ===
              "/static/media/lavendor-color.2b158919bece3419437f.png"
            ? "#602f84"
            : selectedColor ===
              "/static/media/blue-color.eed63dd9c51401c338a6.png"
            ? "#00a0e3"
            : "#e8378b",
      }}
    >
      <div
        className="main-head main-head-join-form choose-color-main-head"
        style={{
          backgroundColor:
            selectedColor ===
            "/static/media/pink-color.aaee0039f5215db2bf72.png"
              ? "#e8378b"
              : selectedColor ===
                "/static/media/green-color.4d0a2dcf4e746215a4f4.png"
              ? "#5ca595"
              : selectedColor ===
                "/static/media/lavendor-color.2b158919bece3419437f.png"
              ? "#602f84"
              : selectedColor ===
                "/static/media/blue-color.eed63dd9c51401c338a6.png"
              ? "#00a0e3"
              : "#e8378b",
        }}
      >
        <div className="choose-color-header left-choose-color-header">
          <img src={HeadingPage3} alt="heading-merck" />
        </div>

        <div className="choose-color-header hide-choose-color-header">
          <img
            className="heading-2-page-4"
            src={Heading2Page3}
            alt="heading-merck"
          />
        </div>
      </div>
      <div className="main-content choose-color-main-content">
        <div className="home-page-sections-wrapper">
          <div className="home-left-section details-home-left-section">
            <div className="choosed-color-image">
              {/* <img src={selectedColor} alt="choosed color" /> */}
              {selectedColor ===
                "/static/media/pink-color.aaee0039f5215db2bf72.png" && (
                <img src={ButterFlys} alt="Selected" className="image" />
              )}
              {selectedColor ===
                "/static/media/green-color.4d0a2dcf4e746215a4f4.png" && (
                <img src={ButterFlyGifGreen} alt="Selected" className="image" />
              )}
              {selectedColor ===
                "/static/media/lavendor-color.2b158919bece3419437f.png" && (
                <img
                  src={ButterFlyGifPurpple}
                  alt="Selected"
                  className="image"
                />
              )}
              {selectedColor ===
                "/static/media/blue-color.eed63dd9c51401c338a6.png" && (
                <img src={ButterFlyGifBlue} alt="Selected" className="image" />
              )}
            </div>
          </div>

          <div className="home-right-section choose-file-right-section details-home-right-section">
            <div className="top-caption-details-page">
              <p>I Commit to transform Thyroid Patient's life</p>
              <p className="detail-hightlighted-caption">
                From Dull to Full of Color
              </p>
            </div>

            <div className="image-with-person-qr-detail-main-wrapper">
              <div className="container container1">
                <div className="left">
                  {selectedImage && (
                    <img src={selectedImage} alt="Selected" className="image" />
                  )}
                </div>

                <div className="right">
                  <h2>{storedFormData.title} {storedFormData.name}</h2>
                  <p>
                    {storedFormData.city}, {storedFormData.state}{" "}
                  </p>
                </div>
              </div>
              <div
                className="container container2"
                // style={{
                //   backgroundColor:
                //     selectedColor ===
                //     "/static/media/pink-color.aaee0039f5215db2bf72.png"
                //       ? "#e8378b"
                //       : selectedColor ===
                //         "/static/media/green-color.4d0a2dcf4e746215a4f4.png"
                //       ? "#5ca595"
                //       : selectedColor ===
                //         "/static/media/lavendor-color.2b158919bece3419437f.png"
                //       ? "#602f84"
                //       : selectedColor ===
                //         "/static/media/blue-color.eed63dd9c51401c338a6.png"
                //       ? "#00a0e3"
                //       : "transperant",
                // }}
              >
                <div className="left">
                  {/* <div className="qr-code-image-wrapper"> */}
                  <img src={QrCode} alt="qr code" className="image image-qr" />
                  {/* </div> */}
                </div>
                <div className="thyroid-text-qr-code">
                  <h1>Thyroid Symptom Checker</h1>
                </div>
              </div>
            </div>

            <div className="top-caption-details-page">
              <p className="detail-hightlighted-caption">
                Early Detection, Early treatement!
              </p>
            </div>
          </div>
        </div>
        <div className="other-details other-details-join-form ">
          <p>*Data as on Merck File</p>
        </div>
      </div>

      {/* <div className="other-details other-details-join-form">
        <p>*Data as on Merck File</p>
      </div> */}
      <div
        className="main-bottom"
        style={{
          backgroundColor:
            selectedColor ===
            "/static/media/pink-color.aaee0039f5215db2bf72.png"
              ? "#e8378b"
              : selectedColor ===
                "/static/media/green-color.4d0a2dcf4e746215a4f4.png"
              ? "#5ca595"
              : selectedColor ===
                "/static/media/lavendor-color.2b158919bece3419437f.png"
              ? "#602f84"
              : selectedColor ===
                "/static/media/blue-color.eed63dd9c51401c338a6.png"
              ? "#00a0e3"
              : "transperant",
        }}
      >
        <div className="next-and-backward-button-wrapper">
          <div
            className="back-button"
            onClick={() => navigate("/merck-choose-color")}
          >
            <div className="next-arrow-icon">
              <IoMdArrowRoundBack className="backward-forward-icon" />
            </div>
            <p>BACK</p>
          </div>
          <div
            className="next-button"
            // onClick={() => navigate("/merck-thank-you")}
            onClick={handleNextClick}
          >
            <p>NEXT</p>
            <div className="back-arrow-icon">
              <IoMdArrowRoundForward className="backward-forward-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsPage;
