import React, { useEffect, useState } from "react";
import axios from "axios";
import "../styles/HomePage.css";
import WomenButterfly from "../Images/women-butterfly.gif";
import { IoMdArrowRoundBack, IoMdArrowRoundForward } from "react-icons/io";
import { useNavigate } from "react-router";
import HeadingPageTwo from "../Images/heading-page-2.png";
import CaptionMain from "../Images/main-caption-heading2.png";
import MerckLogo from "../Images/merck-logo.png";
import "../styles/FormPage.css";

import CaptionFirstLine from "../Images/form-page-head-first-line.png";
import CaptionSecondLine from "../Images/form-page-head-second-line.png";
import FormPageMobileTitleTop from "../Images/form-page-mobile-title-top.png";
import FormPageMobileTitleBottom from "../Images/form-page-mobile-title-bottom.png";

const FormPage = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [profileData, setProfileData] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log("profile data}}}", profileData);
  // Function to validate email format
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Function to validate mobile number format (basic validation for 10-digit number)
  const validateMobileNumber = (number) => {
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(number);
  };

  const statesInIndia = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];
  const Title=[
     "Dr",
     "Mr",
     "Mrs",
     "Miss"
  ]

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contactNo: "",
    city: "",
    state: "",
    title: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    const storedFormData = localStorage.getItem("formData");
    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    localStorage.setItem(
      "formData",
      JSON.stringify({ ...formData, [name]: value })
    );
  };
  const handleSubmit = async () => {
    let newErrors = {};
        // Validate Title
        if (!formData.title.trim()) {
          newErrors = { ...newErrors, title: "Title is required" };
        }
    // Validate name
    if (!formData.name.trim()) {
      newErrors = { ...newErrors, name: "Name is required" };
    }
    // Validate email
    if (!formData.email.trim()) {
      newErrors = { ...newErrors, email: "Email is required" };
    } else if (!validateEmail(formData.email)) {
      newErrors = { ...newErrors, email: "Invalid email format" };
    }
    // Validate contact number
    if (!formData.contactNo.trim()) {
      newErrors = { ...newErrors, contactNo: "Contact number is required" };
    } else if (!validateMobileNumber(formData.contactNo)) {
      newErrors = { ...newErrors, contactNo: "Invalid mobile number format" };
    }
    // Validate city
    if (!formData.city.trim()) {
      newErrors = { ...newErrors, city: "City is required" };
    }
    // Validate state
    if (!formData.state.trim()) {
      newErrors = { ...newErrors, state: "State is required" };
    }
    // Update errors state
    setErrors(newErrors);

    // Check if there are no errors
    if (Object.keys(newErrors).length === 0) {
      try {
        const response = await axios.post(
          "https://www.euthyroxearlyscreeninginitiative.com/register.php",
          formData
        );
        console.log("Response:", response.data);
        // Redirect to next page after successful submission
        navigate("/merck-choose-color");
        console.log("success");
      } catch (error) {
        console.error("Error:", error);
        // Handle error scenarios
      }
    }
  };

  return (
    <div className="main-wrapper ">
      <div className="main-head main-head-join-form">
        <div>
          <h3 className="text-merck">Merck</h3>
        </div>
        <div>
          <img
            src={HeadingPageTwo}
            alt="this women's month"
            className="heading-page-two"
          />
        </div>

        <div className="logoWrapper">
          <img src={MerckLogo} alt="Merck" />
        </div>
      </div>
      <div className="main-content">
        <div className="main-heading-text">
          <img
            src={CaptionMain}
            alt="merck-caption"
            className="form-page-main-head-desktop"
          />
        </div>
        <div className="main-heading-text two-line-main-heading-text">
          <img
            src={CaptionFirstLine}
            alt="merck-caption"
            className="main-head-two-lines "
          />
          <img
            src={CaptionSecondLine}
            alt="merck-caption"
            className="main-head-two-line second-line-main-head-caption-custom"
          />
        </div>
        <div className="home-page-sections-wrapper">
          <div className="home-right-section">
            <div className="women-icon-wrapper women-icon-wrapper-join-form">
              <img src={WomenButterfly} alt="merckwomenicon" />
            </div>
          </div>
          <div className="home-left-section form-page-home-left-section">
            <div className="join-form-main-wrapper">
              <p>Invites You To Join The Movement</p>
              <p>
                To <span className="custom-join-form-text">Add</span>{" "}
                <span>
                  <sup className="sup-back"> back </sup>
                </span>{" "}
                <span className="custom-join-form-text">Color</span> to the life
                of Thyroid Patients
              </p>
              <div className="form-page-mobile-title">
                <img src={FormPageMobileTitleTop} alt="title" />
              </div>
              <div className="form-inputs-wrapper">
                <div>
                  <select
                      name="title"
                      value={formData.title}
                      onChange={handleInputChange}
                  >
                    <option value="">Select Tittle</option>
                    {Title.map((title, index) => (
                      <option key={index} value={title}>
                        {title}
                      </option>
                    ))}
                  </select>
                  <div>
                    {errors.title && (
                      <span className="error">{errors.title}</span>
                    )}
                  </div>
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  <div>
                    {errors.name && (
                      <span className="error">{errors.name}</span>
                    )}
                  </div>
                </div>
                <div>
                  <input
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  <div>
                    {errors.email && (
                      <span className="error">{errors.email}</span>
                    )}
                  </div>
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Contact No"
                    name="contactNo"
                    value={formData.contactNo}
                    onChange={handleInputChange}
                  />
                  <div>
                    {errors.contactNo && (
                      <span className="error">{errors.contactNo}</span>
                    )}
                  </div>
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="City"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                  <div>
                    {errors.city && (
                      <span className="error">{errors.city}</span>
                    )}
                  </div>
                </div>
                <div>
                  <select
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                  >
                    <option value="">Select State</option>
                    {statesInIndia.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                  <div>
                    {errors.state && (
                      <span className="error">{errors.state}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-page-mobile-title">
                <img src={FormPageMobileTitleBottom} alt="title" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="join-form-main-wrapper bottom-sup-content-join-form">
          <p>
            <span className="custom-join-form-text">Add</span>{" "}
            <span>
              <sup className="sup-back"> back </sup>
            </span>{" "}
            <span className="custom-join-form-text">Color...</span>
          </p>
          <p>Tap The symptoms early, Treat Early</p>
        </div>
      </div>

      <div className="other-details other-details-join-form">
        <p>*Data as on Merck File</p>
      </div>
      <div className="main-bottom">
        <div className="next-and-backward-button-wrapper">
          <div className="back-button" onClick={() => navigate("/")}>
            <div className="next-arrow-icon">
              <IoMdArrowRoundBack className="backward-forward-icon" />
            </div>
            <p>BACK</p>
          </div>
          <div className="next-button" onClick={handleSubmit}>
            <p>NEXT</p>{" "}
            <div className="back-arrow-icon">
              <IoMdArrowRoundForward className="backward-forward-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormPage;
